import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are so many types of devices that people can use to launch and engage with voice applications. Whether it’s an Echo Show or a Google Home, developers can now use Dashbot’s Device Capabilities report to see which devices people are using when interacting with voice applications such as Alexa skills and Actions on Google.`}</p>
    <p>{`Under the Audience tab in the left-hand navigation panel, you will find a report called Device Capabilities. The first chart below shows you the following user breakdown:`}</p>
    <ul>
      <li parentName="ul">{`Users who solely accessed your application on an audio-only device (blue)`}</li>
      <li parentName="ul">{`Users who accessed your application on devices with a screen (black)`}</li>
      <li parentName="ul">{`Users who accessed your applications on both types of devices (green)`}</li>
    </ul>
    <p>{`The graph shows you how many people use each type of device daily. You can toggle this graph by number of users and percentage of users.`}</p>
    <p>{`In order to access the Device Capabilities report, you’ll need to configure your voice applications to support display. Please follow the steps and take a look at more detailed documentation below.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/02111702/DeviceCapabilities_Chart-1024x504.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/10/02111732/DeviceCapabilities_Graph-1024x419.png",
        "alt": null
      }}></img></p>
    <h2>{`Configure your Alexa skill or Action on Google to support display`}</h2>
    <h2>{`Device Capabilities for Alexa Skills`}</h2>
    <p>{`1`}{`.`}{` Edit your Alexa skill in the developer console.`}</p>
    <p>{`2`}{`.`}{` Navigate to `}<strong parentName="p">{`Build`}</strong>{` `}{`>`}{` `}<strong parentName="p">{`Custom`}</strong>{` `}{`>`}{` `}<strong parentName="p">{`Interfaces`}</strong>{`.`}</p>
    <p>{`3`}{`.`}{` Enable the `}<strong parentName="p">{`Display Interface`}</strong>{` option, then click Build Model to re-build your interaction model. When you enable Display Interface, the required built-in intents are automatically added to your interaction model.`}</p>
    <p>{`See Amazon Alexa documentation for Alexa-enabled devices with a screen here: `}<a parentName="p" {...{
        "href": "https://developer.amazon.com/docs/custom-skills/create-skills-for-alexa-enabled-devices-with-a-screen.html"
      }}>{`https://developer.amazon.com/docs/custom-skills/create-skills-for-alexa-enabled-devices-with-a-screen.html`}</a></p>
    <h2>{`Surface Capabilities for Actions on Google`}</h2>
    <p>{`To design and build conversations that work well on all surfaces, use surface capabilities to control and scope your conversations properly. You define your Action’s surface support in your Actions project.`}</p>
    <p>{`See Actions on Google documentation for surface capabilities here: `}<a parentName="p" {...{
        "href": "https://developers.google.com/actions/assistant/surface-capabilities"
      }}>{`https://developers.google.com/actions/assistant/surface-capabilities`}</a></p>
    <p><strong parentName="p">{`ABOUT DASHBOT`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is the leading conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable insights. In addition to traditional analytics like engagement and retention, Dashbot provides bot and voice specific analytics and tools including detailed session transcripts, NLP optimization, and conversation paths. We also have interactive tools to take action on the data, like our live-person takeover, broadcast messaging, and audience builder.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+voice+capabilities"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      